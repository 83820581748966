/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryKey } from '@tanstack/react-query'
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendAndReceive, sendOnly } from '@api/utils'
import { IdObject } from '@api/endpoints/types'
import { UpdateImagesOrderRequest } from '@api/endpoints/buildings'
import { OptionId, queryKeys as optionQueryKeys } from '@api/endpoints/buildings/options'
import { CreateImageWithPrimary, FileId } from '@api/endpoints/files'
import {
  CreateLayoutTypeFreeOptionRequest,
  CreateLayoutTypeImageRequest,
  CreateLayoutTypeOptionValuesRequest,
  CreateLayoutTypeRequest,
  CreateLayoutTypeStyleRequest,
  LayoutTypeFreeOptionId,
  LayoutTypeFreeOptions,
  LayoutTypeId,
  LayoutTypeList,
  LayoutTypePublicViewList,
  LayoutTypeStyleId,
  LayoutTypeStyleImages,
  LayoutTypeStyles,
  LayoutTypeView,
  SetPrimaryLayoutTypeImage,
  UpdateLayoutTypeImagesOrderRequest,
  UpdateLayoutTypeOrderRequest,
  UpdateOptionsOrderRequest
} from './types'

const layoutTypeUrl = `${apiBaseUrl}/layout-types`
const layoutTypeOrder = `${layoutTypeUrl}/order`
const layoutTypeIdUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeUrl}/${layoutTypeId}`
const layoutTypeCloneUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeIdUrl(layoutTypeId)}/clone`

const layoutTypesPubicUrl = `${layoutTypeUrl}/public`

const layoutTypeImagesUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeIdUrl(layoutTypeId)}/images`
const layoutTypeImagesOrderUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeImagesUrl(layoutTypeId)}/order`
const layoutTypeImageFileUrl = (layoutTypeId: LayoutTypeId, fileId: FileId): string =>
  `${layoutTypeImagesUrl(layoutTypeId)}/${fileId}`

const layoutTypePrimaryImageUrl = (layoutTypeId: LayoutTypeId, fileId: FileId): string =>
  `${layoutTypeImageFileUrl(layoutTypeId, fileId)}/primary`

const layoutTypePrimaryLandingImageUrl = (layoutTypeId: LayoutTypeId, fileId: FileId): string =>
  `${layoutTypeImageFileUrl(layoutTypeId, fileId)}/primary_landing`

const layoutTypeFreeOptionsUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeIdUrl(layoutTypeId)}/options/free`
const layoutTypeFreeOptionUrl = (layoutTypeId: LayoutTypeId, layoutTypeFreeOptionId: LayoutTypeFreeOptionId): string =>
  `${layoutTypeIdUrl(layoutTypeId)}/options/free/${layoutTypeFreeOptionId}`

const layoutTypeOptionsUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeIdUrl(layoutTypeId)}/options`

const layoutTypeOptionsOrderUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeOptionsUrl(layoutTypeId)}/order`

const layoutTypeOptionUrl = (layoutTypeId: LayoutTypeId, optionId: OptionId): string =>
  `${layoutTypeOptionsUrl(layoutTypeId)}/${optionId}`

const layoutTypeStylesUrl = (layoutTypeId: LayoutTypeId): string => `${layoutTypeIdUrl(layoutTypeId)}/styles`

const layoutTypeStyleUrl = (layoutTypeId: LayoutTypeId, layoutTypeStyleId: LayoutTypeStyleId): string =>
  `${layoutTypeStylesUrl(layoutTypeId)}/${layoutTypeStyleId}`

const layoutTypeStyleImagesUrl = (layoutTypeId: LayoutTypeId, layoutTypeStyleId: LayoutTypeStyleId): string =>
  `${layoutTypeStyleUrl(layoutTypeId, layoutTypeStyleId)}/images`

const layoutTypeStyleImagesOrderUrl = (layoutTypeId: LayoutTypeId, layoutTypeStyleId: LayoutTypeStyleId): string =>
  `${layoutTypeStyleImagesUrl(layoutTypeId, layoutTypeStyleId)}/order`

const layoutTypeStyleImageFileUrl = (
  layoutTypeId: LayoutTypeId,
  layoutTypeStyleId: LayoutTypeStyleId,
  fileId: FileId
): string => `${layoutTypeStyleImagesUrl(layoutTypeId, layoutTypeStyleId)}/${fileId}`

const layoutTypeStylePrimaryImageUrl = (
  layoutTypeId: LayoutTypeId,
  layoutTypeStyleId: LayoutTypeStyleId,
  fileId: FileId
): string => `${layoutTypeStyleImageFileUrl(layoutTypeId, layoutTypeStyleId, fileId)}/primary`

export const queryKeys = {
  getLayoutTypes: ['getLayoutTypes'],

  getLayoutTypeAny: ['getLayoutType'],
  getLayoutType: (layoutTypeId: LayoutTypeId): QueryKey => [...queryKeys.getLayoutTypeAny, layoutTypeId],

  getLayoutTypeImagesAll: ['getLayoutTypeImages'],
  getLayoutTypeImages: (layoutTypeId: LayoutTypeId): QueryKey => [...queryKeys.getLayoutTypeImagesAll, layoutTypeId],

  getLayoutTypeFreeOptionsAll: ['getLayoutTypeFreeOptions'],
  getLayoutTypeFreeOptions: (layoutTypeId: LayoutTypeId): QueryKey => [
    ...queryKeys.getLayoutTypeFreeOptionsAll,
    layoutTypeId
  ],

  getLayoutTypeStylesAll: ['getLayoutTypeStyles'],
  getLayoutTypeStyles: (layoutTypeId: LayoutTypeId): QueryKey => [...queryKeys.getLayoutTypeStylesAll, layoutTypeId],

  getLayoutTypeStyleImagesAll: ['getLayoutTypeStyleImages'],
  getLayoutTypeStyleImages: (layoutTypeId: LayoutTypeId, layoutTypeStyleId: LayoutTypeStyleId): QueryKey => [
    ...queryKeys.getLayoutTypeStyleImagesAll,
    layoutTypeId,
    layoutTypeStyleId
  ]
}

const affectedKeysByCreateOrDelete = [
  queryKeys.getLayoutTypes,
  optionQueryKeys.getOptionsAll,
  optionQueryKeys.getOptionAny,
  optionQueryKeys.getOptionValuesAll,
  optionQueryKeys.getOptionValueAny
]

export const useGetLayoutTypesPublic = (opts?: ApiQueryOpts<LayoutTypePublicViewList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: layoutTypesPubicUrl,
        responseSchema: LayoutTypePublicViewList
      }),
    queryKey: queryKeys.getLayoutTypes,
    ...opts
  })

export const useGetLayoutTypes = (opts?: ApiQueryOpts<LayoutTypeList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: layoutTypeUrl,
        responseSchema: LayoutTypeList
      }),
    queryKey: queryKeys.getLayoutTypes,
    ...opts
  })

export const useCreateLayoutType = (opts?: ApiMutationOpts<IdObject, CreateLayoutTypeRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: layoutTypeUrl,
        requestSchema: CreateLayoutTypeRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })

type UpdateLayoutTypeRequest = CreateLayoutTypeRequest & {
  id: LayoutTypeId
}

export const useUpdateLayoutType = (opts?: ApiMutationOpts<void, UpdateLayoutTypeRequest>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: layoutTypeIdUrl(id),
        requestSchema: CreateLayoutTypeRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypes],
    ...opts
  })

export const useGetLayoutType = (layoutTypeId: LayoutTypeId, opts?: ApiQueryOpts<LayoutTypeView>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: layoutTypeIdUrl(layoutTypeId),
        responseSchema: LayoutTypeView
      }),
    queryKey: queryKeys.getLayoutType(layoutTypeId),
    ...opts
  })

export const useDeleteLayoutType = (opts?: ApiMutationOpts<void, LayoutTypeId>) =>
  useApiMutation({
    mutationFn: (layoutTypeId) =>
      callOnly({
        method: 'delete',
        url: layoutTypeIdUrl(layoutTypeId)
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })

export const useCloneLayoutType = (opts?: ApiMutationOpts<IdObject, LayoutTypeId>) =>
  useApiMutation({
    mutationFn: (layoutTypeId) =>
      receiveOnly({
        method: 'post',
        url: layoutTypeCloneUrl(layoutTypeId),
        responseSchema: IdObject
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })

export const useUpdateLayoutTypesOrder = (opts?: ApiMutationOpts<void, UpdateLayoutTypeOrderRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendOnly({
        method: 'put',
        url: layoutTypeOrder,
        requestSchema: UpdateLayoutTypeOrderRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypes, queryKeys.getLayoutTypeAny],
    ...opts
  })

type WithLayoutTypeIdParam = {
  layoutTypeId: LayoutTypeId
}

type CreateLayoutTypeImageRequestWithLayoutTypeId = CreateLayoutTypeImageRequest & WithLayoutTypeIdParam

export const useCreateLayoutTypeImage = (
  opts?: ApiMutationOpts<IdObject, CreateLayoutTypeImageRequestWithLayoutTypeId>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: layoutTypeImagesUrl(layoutTypeId),
        requestSchema: CreateLayoutTypeImageRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeImagesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useDeleteLayoutTypeImage = (opts?: ApiMutationOpts<void, WithLayoutTypeIdAndFileIdParams>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, fileId }) =>
      callOnly({
        method: 'delete',
        url: layoutTypeImageFileUrl(layoutTypeId, fileId)
      }),
    invalidateQueries: [queryKeys.getLayoutTypeImagesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

type WithLayoutTypeIdAndFileIdParams = WithLayoutTypeIdParam & {
  fileId: FileId
}

type SetLayoutTypePrimaryImageWithParams = SetPrimaryLayoutTypeImage & WithLayoutTypeIdAndFileIdParams

export const useSetLayoutTypePrimaryImage = (opts?: ApiMutationOpts<void, SetLayoutTypePrimaryImageWithParams>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, fileId, ...data }) =>
      sendOnly({
        method: 'post',
        url: layoutTypePrimaryImageUrl(layoutTypeId, fileId),
        requestSchema: SetPrimaryLayoutTypeImage,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeImagesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useSetLayoutTypeLandingPrimaryImage = (opts?: ApiMutationOpts<void, WithLayoutTypeIdAndFileIdParams>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, fileId }) =>
      callOnly({
        method: 'post',
        url: layoutTypePrimaryLandingImageUrl(layoutTypeId, fileId)
      }),
    invalidateQueries: [queryKeys.getLayoutTypeImagesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

type UpdateLayoutTypeImagesOrderRequestWithParams = UpdateLayoutTypeImagesOrderRequest & WithLayoutTypeIdParam

export const useUpdateLayoutTypeImagesOrder = (
  opts?: ApiMutationOpts<void, UpdateLayoutTypeImagesOrderRequestWithParams>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, ...data }) =>
      sendOnly({
        method: 'put',
        url: layoutTypeImagesOrderUrl(layoutTypeId),
        requestSchema: UpdateLayoutTypeImagesOrderRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeImagesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useGetLayoutTypeFreeOptions = (layoutTypeId: LayoutTypeId, opts?: ApiQueryOpts<LayoutTypeFreeOptions>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: layoutTypeFreeOptionsUrl(layoutTypeId),
        responseSchema: LayoutTypeFreeOptions
      }),
    queryKey: queryKeys.getLayoutTypeFreeOptions(layoutTypeId),
    ...opts
  })

type CreateLayoutTypeFreeOptionRequestWithParam = CreateLayoutTypeFreeOptionRequest & WithLayoutTypeIdParam

export const useCreateLayoutTypeFreeOption = (
  opts?: ApiMutationOpts<IdObject, CreateLayoutTypeFreeOptionRequestWithParam>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: layoutTypeFreeOptionsUrl(layoutTypeId),
        requestSchema: CreateLayoutTypeFreeOptionRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeFreeOptionsAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

type DeleteLayoutTypeFreeOptionRequest = WithLayoutTypeIdParam & {
  layoutTypeFreeOptionId: LayoutTypeFreeOptionId
}

export const useDeleteLayoutTypeFreeOption = (opts?: ApiMutationOpts<void, DeleteLayoutTypeFreeOptionRequest>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, layoutTypeFreeOptionId }) =>
      callOnly({
        method: 'delete',
        url: layoutTypeFreeOptionUrl(layoutTypeId, layoutTypeFreeOptionId)
      }),
    invalidateQueries: [queryKeys.getLayoutTypeFreeOptionsAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

type WithLayoutTypeIdAndOptionIdParams = WithLayoutTypeIdParam & {
  optionId: OptionId
}

type CreateLayoutTypeOptionValuesRequestWithParams = CreateLayoutTypeOptionValuesRequest &
  WithLayoutTypeIdAndOptionIdParams

export const useCreateLayoutTypeOptionValues = (
  opts?: ApiMutationOpts<void, CreateLayoutTypeOptionValuesRequestWithParams>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, optionId, ...data }) =>
      sendOnly({
        method: 'post',
        url: layoutTypeOptionUrl(layoutTypeId, optionId),
        requestSchema: CreateLayoutTypeOptionValuesRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useUpdateLayoutTypeOptionValues = (
  opts?: ApiMutationOpts<void, CreateLayoutTypeOptionValuesRequestWithParams>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, optionId, ...data }) =>
      sendOnly({
        method: 'put',
        url: layoutTypeOptionUrl(layoutTypeId, optionId),
        requestSchema: CreateLayoutTypeOptionValuesRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeAny],
    ...opts
  })

type UpdateOptionsOrderRequestWithParam = UpdateOptionsOrderRequest & WithLayoutTypeIdParam

export const useUpdateLayoutTypeOptionsOrder = (opts?: ApiMutationOpts<void, UpdateOptionsOrderRequestWithParam>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, ...data }) =>
      sendOnly({
        method: 'put',
        url: layoutTypeOptionsOrderUrl(layoutTypeId),
        requestSchema: UpdateOptionsOrderRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useDeleteLayoutTypeOptionValues = (opts?: ApiMutationOpts<void, WithLayoutTypeIdAndOptionIdParams>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, optionId }) =>
      callOnly({
        method: 'delete',
        url: layoutTypeOptionUrl(layoutTypeId, optionId)
      }),
    invalidateQueries: [queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useGetLayoutTypeStyles = (layoutTypeId: LayoutTypeId, opts?: ApiQueryOpts<LayoutTypeStyles>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: layoutTypeStylesUrl(layoutTypeId),
        responseSchema: LayoutTypeStyles
      }),
    queryKey: queryKeys.getLayoutTypeStyles(layoutTypeId),
    ...opts
  })

type CreateLayoutTypeStyleRequestWithParams = CreateLayoutTypeStyleRequest & WithLayoutTypeIdParam

export const useCreateLayoutTypeStyle = (opts?: ApiMutationOpts<IdObject, CreateLayoutTypeStyleRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, ...data }) =>
      sendAndReceive({
        method: 'post',
        url: layoutTypeStylesUrl(layoutTypeId),
        requestSchema: CreateLayoutTypeStyleRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeStylesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

type UpdateLayoutTypeStyleRequest = CreateLayoutTypeStyleRequest & WithLayoutTypeIdAndLayoutTypeStyleIdParams

export const useUpdateLayoutTypeStyle = (opts?: ApiMutationOpts<void, UpdateLayoutTypeStyleRequest>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeStyleId, layoutTypeId, ...data }) =>
      sendOnly({
        method: 'put',
        url: layoutTypeStyleUrl(layoutTypeId, layoutTypeStyleId),
        requestSchema: CreateLayoutTypeStyleRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeStylesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useDeleteLayoutTypeStyle = (opts?: ApiMutationOpts<void, WithLayoutTypeIdAndLayoutTypeStyleIdParams>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, layoutTypeStyleId }) =>
      callOnly({
        method: 'delete',
        url: layoutTypeStyleUrl(layoutTypeId, layoutTypeStyleId)
      }),
    invalidateQueries: [queryKeys.getLayoutTypeStylesAll, queryKeys.getLayoutTypeAny],
    ...opts
  })

export const useGetLayoutTypeStyleImages = (
  layoutTypeId: LayoutTypeId,
  layoutTypeStyleId: LayoutTypeStyleId,
  opts?: ApiQueryOpts<LayoutTypeStyleImages>
) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: layoutTypeStyleImagesUrl(layoutTypeId, layoutTypeStyleId),
        responseSchema: LayoutTypeStyleImages
      }),
    queryKey: queryKeys.getLayoutTypeStyleImages(layoutTypeId, layoutTypeStyleId),
    ...opts
  })

type WithLayoutTypeIdAndLayoutTypeStyleIdParams = WithLayoutTypeIdParam & {
  layoutTypeStyleId: LayoutTypeStyleId
}

type CreateLayoutTypeStyleImageRequest = CreateImageWithPrimary & WithLayoutTypeIdAndLayoutTypeStyleIdParams

export const useCreateLayoutTypeStyleImage = (opts?: ApiMutationOpts<void, CreateLayoutTypeStyleImageRequest>) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, layoutTypeStyleId, ...data }) =>
      sendOnly({
        method: 'post',
        url: layoutTypeStyleImagesUrl(layoutTypeId, layoutTypeStyleId),
        requestSchema: CreateImageWithPrimary,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeStyleImagesAll],
    ...opts
  })

type WithLayoutIdAndLayoutTypeStyleAndFileIdParams = WithLayoutTypeIdAndLayoutTypeStyleIdParams &
  WithLayoutTypeIdAndFileIdParams

export const useDeleteLayoutTypeStyleImage = (
  opts?: ApiMutationOpts<void, WithLayoutIdAndLayoutTypeStyleAndFileIdParams>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, layoutTypeStyleId, fileId }) =>
      callOnly({
        method: 'delete',
        url: layoutTypeStyleImageFileUrl(layoutTypeId, layoutTypeStyleId, fileId)
      }),
    invalidateQueries: [queryKeys.getLayoutTypeStyleImagesAll],
    ...opts
  })

export const useSetPrimaryLayoutTypeStyleImage = (
  opts?: ApiMutationOpts<void, WithLayoutIdAndLayoutTypeStyleAndFileIdParams>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, layoutTypeStyleId, fileId }) =>
      callOnly({
        method: 'put',
        url: layoutTypeStylePrimaryImageUrl(layoutTypeId, layoutTypeStyleId, fileId)
      }),
    invalidateQueries: [queryKeys.getLayoutTypeStyleImagesAll],
    ...opts
  })

export type UpdateLayoutTypeStyleImagesOrderRequest = UpdateImagesOrderRequest &
  WithLayoutTypeIdAndLayoutTypeStyleIdParams

export const useUpdateLayoutTypeStyleImagesOrder = (
  opts?: ApiMutationOpts<void, UpdateLayoutTypeStyleImagesOrderRequest>
) =>
  useApiMutation({
    mutationFn: ({ layoutTypeId, layoutTypeStyleId, ...data }) =>
      sendOnly({
        method: 'put',
        url: layoutTypeStyleImagesOrderUrl(layoutTypeId, layoutTypeStyleId),
        requestSchema: UpdateImagesOrderRequest,
        data
      }),
    invalidateQueries: [queryKeys.getLayoutTypeStyleImagesAll],
    ...opts
  })
